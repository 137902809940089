<template>
    <div>
        <div class="banner1"></div>
        <div class="banner2">
            <h4>个人荣誉</h4>
            <div class="banner2_text">
                <div class="left">
                    <p>· 中国企业年度经济人物（2020）</p>
                    <p>· 荣获光荣浙商（2018）</p>
                    <p>· 中国十大经济影响力人物（2018）</p>
                    <p>· 中国优秀诚信企业家（2016）</p>
                    <p>· 中国财经年度人物（2016）</p>
                </div>
                <div class="right">
                    <p>·《商业观察》封面人物（2016）</p>
                    <p>·《联合国友好画报》封面人物（2016）</p>
                    <p>· 宁波市优秀企业家（2015）</p>
                    <p>· 中国十大新能源人物（2010）</p>
                    <p>· 中国十大杰出 CEO（2010）</p>
                </div>
            </div>
            <div class="swiper clear-fix">
                <div class="pre" @click="toogle(-1,'personal')"></div>
                <div v-for="c of 4" :key="c" class="photoList"><div class="photo-box" :style="{ backgroundImage: personalShowList[c-1] }"></div></div>
                <div class="next" @click="toogle(1,'personal')"></div>
            </div>
            <h4 class="mb">社会职务</h4>
            <div class="banner2_text">
                <div class="left">
                    <p>· 北京浙江企业商会常务副会长</p>
                    <p>· 美中企业家商会高级顾问及上海商会会长</p>
                    <p>· 浙商总会互联网专业委员会副主席</p>
                    <p>· 中国创新设计产业联盟副理事长</p>
                </div>
                <div class="right">
                    <p>· 上海市工商联执委</p>
                    <p>· 浦东新区工商联副主席</p>
                    <p>· 浙江大学上海校友会会长</p>
                    <p>· 浙江大学控制学院校友会会长</p>
                </div>
                <p class="rp">· 上海市工商联执委</p>
            </div>
            <div class="swiper clear-fix">
                <div class="pre" @click="toogle(-1,'social')"></div>
                <div v-for="c of 4" :key="c" class="photoList"><div :style="{ backgroundImage: socialShowList[c-1] }" class="photo-box"></div></div>
                <div class="next" @click="toogle(1,'social')"></div>
            </div>
        </div>
        <div class="banner3">
            <h2>企业简介</h2>
            <div class="text">师董会【Sunrise New Energy Co.,Ltd.】是NASDAQ上市公司（EPOW.US），中国总部位于山东淄博，主要业务包括智联网企业服务生态链、新能源锂电池材料等领域。通过“平台 + 投资 + 实业”的模式，为政府招商、企业发展、新能源产业建设提供服务。<br>2014年，为助力广大中小企业创新创业、转型升级，师董会诚邀中国企业界、科学界、投资界众多杰出人士担任实战导师，旨在通过数字技术，分享杰出人士的顶尖智慧，为广大中小企业提供实战帮扶。经过多年发展，师董会形成了数字技术、产业智慧、商业资源、资本力量多位一体的智联网平台模式，创建“智慧共享、企业互助”的企业服务生态链，提供企业服务、产业投资、产业运营、产融结合的生态服务。<br>2021年，在中国“双碳”战略规划及全球新能源锂电池产业高速发展的背景下，师董会汇聚中国顶尖的新能源产业人士，组建晖阳新能源，携全球顶尖的锂电池材料技术工艺及丰富的客户沉淀，大力进军锂电池材料领域，为支援新能源建设发展再开篇章。</div>
        </div>
        <div class="banner4">
            <img src="https://cdn.sdh365.com/person_info.png" class="person_info">
        </div>
        <div class="banner6">
            <div style="text-align:center">
                <h3 class="app">企业</h3><h3 class="highlight">文化</h3>
            </div>
            <div class="info">师董会致力于打造智联网——大咖引领的智慧共享及企业互助的人联网平台</div>
            <div class="flex">
                <div class="box mr">
                    <img src="../../assets/about_icon1.png">
                    <div class="title">定位</div>
                    <div class="text">大咖引领的智慧共享<br>及企业互助智联网平台</div>
                </div>
                <div class="box mr">
                    <img src="../../assets/about_icon2.png">
                    <div class="title">价值观</div>
                    <div class="text">责任、创新、包容、共享</div>
                </div>
                <div class="box">
                    <img src="../../assets/about_icon3.png">
                    <div class="title">愿景（目标）</div>
                    <div class="text">全球领先的智慧交易平台</div>
                </div>
            </div>
            <div class="flex">
                <div class="box mr">
                    <img src="../../assets/about_icon4.png">
                    <div class="title">特色</div>
                    <div class="text">互相学习、互相帮助<br>互相交易、互相投资</div>
                </div>
                <div class="box mr">
                    <img src="../../assets/about_icon5.png">
                    <div class="title">品牌主张</div>
                    <div class="text">智汇创造价值</div>
                </div>
                <div class="box">
                    <img src="../../assets/about_icon6.png">
                    <div class="title">广告语</div>
                    <div class="text">找大咖，就上师董会</div>
                </div>
            </div>
        </div>
        <div class="banner5_1"></div>
        <div class="banner7">
            <h3 class="app">企业荣誉</h3>
            <div class="info">股票代码：EPOW.US</div>
            <div class="swiper clear-fix">
                <div class="pre" @click="toogle(-1,'company')">&lt;&lt;</div>
                <div v-for="c of 4" :key="c" class="photoList">
                    <div :style="{ backgroundImage: companyShowList[c-1].url }" class="photo-box"></div>
                    <div class="text">{{companyShowList[c-1].text}}</div>
                </div>
                <div class="next" @click="toogle(1,'company')">&gt;&gt;</div>
            </div>
        </div>
        <div class="banner8" id="contact">
            <div class="left">
                <div class="title">你想沟通的问题</div>
                <div class="want">
                    <p>我要成为师董会的会员</p>
                    <p>我要成为师董会的导师</p>
                    <p>我想加入师董会</p>
                    <p>我要代理师董会的产品</p>
                    <p>我要成为师董会的专家</p>
                    <p>我有更好的建议帮助师董会</p>
                </div>
            </div>
            <div class="right">
                <el-form :inline="true" :model="data">
                    <el-form-item label="我是谁">
                        <el-input v-model="data.name" class="input" placeholder="请输入..."></el-input>
                    </el-form-item>
                    <el-form-item label="怎么联系我">
                        <el-input v-model="data.mobile" class="input" placeholder="请输入..."></el-input>
                    </el-form-item>
                    <el-form-item label="所属企业/机构">
                        <el-input v-model="data.company" class="input" placeholder="请输入..."></el-input>
                    </el-form-item>
                    <el-form-item label="我的邮箱">
                        <el-input v-model="data.emil" class="input" placeholder="请输入..."></el-input>
                    </el-form-item>
                    <el-form-item label="我想说的">
                        <el-input v-model="data.content" class="input" type="textarea" placeholder="请输入..." rows="4"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-button class="banner8_btn" type="primary" @click="submit">提交</el-button>
    </div>
</template>

<script>
import request from '@/utils/request'
import { Message } from 'element-ui'
export default {
    name:"About-us",
    data(){
        return{
           banner10_tab: "chanye",
           picNum: 5,
           picList:[
               {
                   color:"pink",
                   num: 1
               },
               {
                   color:"orange",
                   num: 2
               },
               {
                   color:"yellow",
                   num: 3
               },
               {
                   color:"green",
                   num: 4
               },
               {
                   color:"blue",
                   num: 5
               },
               {
                   color:"cyan",
                   num: 6
               },
               {
                   color:"purple",
                   num: 7
               },
               {
                   color:"gray",
                   num: 8
               },
           ],
           personalStart: 0,
           personalEnd: 5,
           socialStart: 0,
           socialEnd: 5,
           companyStart:0,
           companyEnd:5,
           picStart: 0,
           picEnd: 5,
           picShowList:[],
           data: {
                name: '',
                mobile: '',
                company: '',
                emil: '',
                content: '',
           },
           personalList:[
               "url(" + require('../../assets/zheshang.png') + ")",
               "url(" + require('../../assets/shanshan.png') + ")",
               "url(" + require('../../assets/ceo.png') + ")",
               "url(" + require('../../assets/zhengshu.png') + ")",
               "url(" + require('../../assets/mingxie.png') + ")",
               "url(" + require('../../assets/jiangbei.png') + ")",
               "url(" + require('../../assets/renwu.png') + ")",
           ],
           socialList:[
               "url(" + require('../../assets/zhuxi.png') + ")",
               "url(" + require('../../assets/xiaoyou.png') + ")",
               "url(" + require('../../assets/unops.png') + ")",
               "url(" + require('../../assets/pinshu.png') + ")",
               "url(" + require('../../assets/lishi.png') + ")",
           ],
           companyList:[
               {
                   url:"url(" + require('../../assets/gaoxin.png') + ")",
                   text:'通过“高新技术企业”认定'
               },
               {
                   url:"url(" + require("../../assets/zhongguancun.png") + ")",
                   text:'通过“中关村高新技术企业”认定'
               },
               {
                   url:"url(" + require("../../assets/jiechu.png") + ")",
                   text:'被浙江日报和全球共荣浙商论坛组委\n共同授予“浙江省高质量发展杰出企业”荣誉称号'
               },
               {
                   url:"url(" + require("../../assets/jingjirenwu.png") + ")",
                   text:'被经济日报社（集团）《经济》杂志社\n授予“2020中国智慧经济十大标杆企业”荣誉称号'
               }
           ],
           swiperList:[
               {
                   time:'2022年2月',
                   event:'师董会入选洛阳市首批智库签约机构'
               },
               {
                   time:'2022年3月',
                   event:'与山西临汾古县合作年产3万吨石墨烯改性负极材料项目'
               },
               {
                   time:'2022年4月',
                   event:'签订投资协议成立高端动力锂离子电池负极材料合资企业'
               },
               {
                   time:'2014年12月',
                   event:'北京华泰益合（师董会前身）正式成立'
               },
               {
                   time:'2016年5月',
                   event:'师董会APP上线'
               },
               {
                   time:'2016年6月',
                   event:'师董会总部（上海）成立，胡海平正式全身心投入师董会'
               },
               {
                   time:'2016年10月',
                   event:'推出首期线下游学活动'
               },
               {
                   time:'2016年12月',
                   event:'与濮阳市政府、紫金港资本签订战略合作协议，建立产业基金；师董会董事长胡海平荣获2016财经年度人物'
               },
               {
                   time:'2017年3月',
                   event:'全国人大常委会原副委员长、中国科学院原院长路甬祥题词贺勉师董会董事长胡海平'
               },
               {
                   time:'2017年6月',
                   event:'华泰益合正式更名为师董会'
               },
               {
                   time:'2017年8月',
                   event:'胡海平受邀出席宾大沃顿中美峰会，师董会价值观获总领事章启月女士褒奖'
               },
               {
                   time:'2017年10月',
                   event:'师董会荣获中国国家高新技术企业'
               },
               {
                   time:'2018年8月',
                   event:'由沈阳市政府主办、师董会承办的第十届APEC科技展会在沈阳举办；获得中国证券基金投资业协会牌照P1068498；与沈阳市政府共同投资设立总额为3亿元的师董科技产业引导基金'
               },
               {
                   time:'2018年12月',
                   event:'发行第一支科技创投股权投资基金SEU174'
               },
               {
                   time:'2019年1月',
                   event:'全国首家“产业中国师董赋能城市馆”落户汝州'
               },
               {
                   time:'2019年9月',
                   event:'师董会递交IPO申请；在沈阳承办由商务部、发改委、科技部、辽宁省政府主办的第十八届中国国际装备制造业博览会工业互联网产融推进论坛'
               },
               {
                   time:'2019年11月',
                   event:'澳洲彼岸师董会财富俱乐部成立'
               },
               {
                   time:'2019年12月',
                   event:'师董会马来西亚企业家俱乐部成立'
               },
               {
                   time:'2020年6月',
                   event:'师董会携手F50全球资本峰会，中美印欧共话全球网络盛会'
               },
               {
                   time:'2020年10月',
                   event:'师董会落户淄博'
               },
               {
                   time:'2020年11月',
                   event:'师董会赴美上市获得纳斯达克批准，交易代码：SDH 经济日报社（集团）《经济》杂志社主办、师董会承办的2020智慧经济发展高峰论坛暨年度经济人物颁奖盛典在淄博召开'
               },
               {
                   time:'2021年2月',
                   event:'师董会正式登陆纳斯达克，股票交易代码：SDH'
               },
               {
                   time:'2021年5月',
                   event:'与淄博市政府签约师董会数字贸易产业基金，规模4000万美元'
               },
               {
                   time:'2021年9月',
                   event:'与贵州达成年产10万吨高端动力锂离子电池负极材料项目战略合作，总投资33亿元；师董会正式将业务扩展到职业教育市场'
               },
           ],
           circleStart: 0,
           circleEnd: 5,
           personalShowList:[],
           swiperShowList:[],
           socialShowList:[],
           companyShowList:[],
        }
    },
    created(){
        this.picShowList = this.picList.slice(this.picStart,this.picEnd)
        this.personalShowList = this.personalList.slice(this.personalStart,this.personalEnd)
        this.socialShowList = this.socialList.slice(this.socialStart,this.socialEnd)
        this.companyShowList = this.companyList.slice(this.companyStart,this.companyEnd)
        this.swiperShowList = this.swiperList.slice(this.circleStart,this.circleEnd)
    },
    mounted(){
        if(this.$route.query.contact){
            this.signUp()
        }
    },
    methods:{
       toogle(n,type){
           var start='',end='',list='',showList='';
           if(type == "pic") start = "picStart", end = "picEnd", list = "picList", showList = "picShowList"
           if(type == "personal") start = "personalStart", end = "personalEnd", list = "personalList", showList = "personalShowList"
           if(type == "social") start = "socialStart", end = "socialEnd", list = "socialList", showList = "socialShowList"
           if(type == "company") start = "companyStart", end = "companyEnd", list = "companyList", showList = "companyShowList"
           if(type == "circle") start = "circleStart", end = "circleEnd", list = "swiperList", showList = "swiperShowList"
           this[showList] = []
           
            var sNum = (this[start] + n) % this[list].length,eNum = (this[end] + n) % this[list].length
            if(eNum == 0) eNum = this[list].length
            if((this[list].length - sNum) < (this[end]-this[start])){
                this[showList] = this[list].slice(sNum - this[list].length)
                this[list].slice(0, eNum).map(n => {
                    this[showList].push(n)
                })
            }
            else if((this[list].length - sNum) > this[list].length){
                this[showList] = this[list].slice(sNum)
                this[list].slice(0, eNum).map(n => {
                    this[showList].push(n)
                })
            }
            else {
                this[showList] = this[list].slice(sNum, eNum)
            }
           this[start] = this[start] + n
           this[end] = this[end] + n
       },
       submit(){
           if(this.data.name == ''){
                Message({
                    message: '请输入您的称呼',
                    type: 'warning',
                    duration: 5 * 1000
                })
                return
            }
            if(this.data.mobile == ''){
                Message({
                    message: '请输入您的电话',
                    type: 'warning',
                    duration: 5 * 1000
                })
                return
            }
            request.post('/in/website/leaveword',this.data).then(() => {
                this.data = {
                    name: '',
                    mobile: '',
                    company: '',
                    emil: '',
                    content: '',
                }
                Message({
                    message: '感谢您的留言',
                    type: 'success',
                    duration: 5 * 1000
                })
            })
       },
       signUp(){
        let returnEle = document.querySelector("#contact");
        if (returnEle) {
            returnEle.scrollIntoView(true);
        }
       }
    }
}
</script>

<style lang="less" scoped>
.swiper{
    margin-top: 52px;
    margin-bottom: 33px;
    display: inline-block;
    width: 1350px;
    .pre,.next{
        width: 40px;
        height: 40px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 70px;
        cursor: pointer;
    }
    .pre{
        float: left;
        background-image: url(../../assets/pre.png);
        margin-right: 35px;
    }
    .next{
        float: right;
        background-image: url(../../assets/next.png);
    }
    .photoList{
        display: block;
        width: 280px;
        height: 180px; 
        float: left;
        margin-right: 27px;
        // overflow: hidden;
    }
    .photo-box{
        // float: left;
        position: relative;
        width: 280px;
        height: 180px;
        // margin-right: 27px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all 1s;
    }
    .photo-box:hover{
        transform: scale(1.1);
        -ms-transform: scale(1.1); /* IE 9 */
        -moz-transform: scale(1.1); /* Firefox */
        -webkit-transform: scale(1.1); /* Safari 和 Chrome */
        -o-transform: scale(1.1); /* Opera */
    }
}
.banner1{
    height: 600px;
    background-image: url(https://cdn.sdh365.com/banner_hu_about.png);
    background-position: center;
    background-size: cover;
}
.banner2{
    padding-top: 170px;
    position: relative;
    text-align: center;
    .banner2_text{
        display: inline-block;
        width: 1200px;
        text-align: left;
    }
    .box{
        display: flex;
        justify-content: space-around;
        width: 1200px;
        height: 180px;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.10); 
        left: 360px;
        top: -70px;
        background-color: #ffffff;
        position: absolute;
        padding-top: 36px;
        .num{
            display: inline-block;
            text-align: center;
            .title{
                line-height: 32px;
                font-size: 18px;
                color: #333333;
            }
            .total{
                display: block;
                line-height: 70px;
                font-size: 40px;
                font-weight: 700;
                color: #000000;
                margin-top: 15px;
            }
        }
    }
    h4{
        text-align: center;
        line-height: 50px;
        font-size: 36px;
        color: #333333;
    }
    .mb{
        margin-bottom: 70px;
    }
    .left,.right{
        display: inline-block;
        line-height: 40px;
    }
    .right{
        margin-left: 130px;
    }
    .rp{
        float: right;
        margin-right: 130px;
        line-height: 40px;
    }
}
.banner3{
    background: linear-gradient(180deg,#ff7b00, #ff5c00 100%);
    padding: 60px 360px;
    text-align: center;
    h2{
        text-align: center;
        line-height: 67px;
        font-size: 48px;
        font-weight: 800;
        color: #ffffff;
        margin-bottom: 14px;
    }
    h5{
        text-align: center;
        line-height: 36px;
        font-size: 26px;
        font-weight: 500;
        color: rgba(255,255,255,0.80);
        margin-bottom: 60px;
    }
    .text{
        display: inline-block;
        width: 1199px;
        font-size: 22px;
        color: #ffffff;
        line-height: 39px;
        white-space:pre-wrap;
        text-align: left;
    }
}
.banner4{
    text-align: center;
    padding-top: 40px;
    padding-bottom: 70px;
    .person_info{
        display: inline-block;
        height: 1731px;
        width: 1201px;
    }
    // padding-top: 65px;
    // padding-bottom: 30px;
    // h3{
    //     display: inline-block;
    //     line-height: 67px;
    //     font-size: 48px;
    //     font-weight: 800;
    //     color: #333333;
    //     margin-bottom: 33px;
    // }
    // .app{
    //     margin-left: 864px;
    // }
    // .highlight{
    //     color: #FF7B00;
    // }
    // .info{
    //     width: 1199px;
    //     line-height: 40px;
    //     font-size: 22px;
    //     color: #333333;
    //     margin-left: 360px;
    // }
}
.banner5{
    height: 860px;
    background-image: url(https://cdn.sdh365.com/ow/banner_aboutUs.png);
    background-position: center;
    background-size: cover;
    padding-top: 1px;
    h3{
        position: absolute;
        left: 863px;
        margin-top: 80px;
        text-align: center;
        line-height: 67px;
        font-size: 48px;
        font-weight: 800;
        color: #ffffff;
    }
    .swiper{
        position: relative;
        .photo-box{
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            background: #ffa24c;
            color: #ffffff;
            border-radius: 50%;
            position: absolute;
        }
        .photo-active{
            background: #fffaf6;
            color: #FF7B00;
            font-weight: 800;
        }
        .item:nth-child(2) .photo-box{
            top: 50px;
            left: 85px;
        }
        .item:nth-child(3) .photo-box{
            top: 210px;
            left: 250px;
        }
        .item:nth-child(4) .photo-box{
            top: 310px;
            left: 607px;
        }
        .item:nth-child(5) .photo-box{
            top: 210px;
            left: 950px;
        }
        .item:nth-child(6) .photo-box{
            top: 50px;
            left: 1120px;
        }
        .text{
            text-align: center;
            width: 480px;
            line-height: 42px;
            font-size: 30px;
            font-weight: 800;
            color: #ffffff;
            position: absolute;
            top: 490px;
            left: 420px;
        }
    }
    .pre{
        float: left;
        background-image: url(../../assets/arrow_l_s.png);
        margin-left: 260px;
        margin-top: 500px;
    }
    .pre:hover{
        background-image: url(../../assets/arrow_l.png);
    }
    .next{
        float: right;
        background-image: url(../../assets/arrow_r_s.png);
        margin-top: 500px;
        margin-right: 320px;
    }
    .next:hover{
        background-image: url(../../assets/arrow_r.png);
    }
}
.banner5_1{
    height: 1057px;
    background-image: url(https://cdn.sdh365.com/history.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.banner6{
    padding-top: 65px;
    padding-bottom: 55px;
    h3{
        display: inline-block;
        line-height: 67px;
        font-size: 48px;
        font-weight: 800;
        color: #333333;
        margin-bottom: 33px;
    }
    .highlight{
        color: #FF7B00;
    }
    .info{
        line-height: 40px;
        font-size: 22px;
        color: #333333;
        text-align: center;
        margin-bottom: 50px;
    }
    .flex{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .box{
            text-align: center;
            img{
                width: 114px;
                height: 114px;
            }
            .title{
                line-height: 36px;
                font-size: 26px;
                font-weight: 800;
                color: #333333;
                margin-bottom: 13px;
            }
            .text{
                width: 220px;
            }
        }
        .mr{
            margin-right: 130px;
        }
    }
}
.banner7{
    padding-top: 55px;
    padding-bottom: 55px;
    height: 580px;
    background: #f7f7f7;
    text-align: center;
    h3{
        line-height: 67px;
        font-size: 48px;
        font-weight: 800;
        margin-bottom: 15px;
        color: #333333;
    }
    .info{
        line-height: 40px;
        font-size: 22px;
        color: #333333;
        text-align: center;
        margin-bottom: 50px;
        color: #333333;
    }
    .pre,.next{
        background: none;
        color: #333333;
        line-height: 30px;
        font-size: 30px;
    }
    .text{
        font-size: 14px;
        line-height: 23px;
        white-space:pre-wrap;
        margin-top:17px;
        color:#333333
    }
}
.banner8{
    padding-top: 80px;
    padding-bottom: 25px;
    // padding-left: 360px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .left{
        display: inline-block;
        height: 560px;
        .title{
            writing-mode: vertical-lr;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction:column; 
            width: 160px;
            height: 560px;
            background: #333333;
            border-radius: 10px 0px 0px 10px;
            font-size: 26px;
            font-weight: 700;
            color: #ffffff;
            letter-spacing: 1em;
            float: left;
        }
        .want{
            background-image: url(../../assets/peopleright.png);
            background-position: center;
            background-size: cover;
            display: inline-block;
            width: 420px;
            height: 560px;
            padding-top: 56px;
            padding-left: 50px;
            text-align: left;
            p{
                margin-bottom: 50px;
                line-height: 34px;
                font-size: 24px;
                font-weight: 700;
                color: #333333;
                cursor: pointer;
            }
            p::before{
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                background: #333333;
                border-radius: 50%;
                margin-right: 20px;
            }
            p:hover{
                color: #FF7B00;
            }
        }
    }
    .right{
        // float: right;
        // margin-right: 360px;
        // display: inline-block;
        margin-left: 10px;
        width: 600px;
        .input {
            width: 557px;
            height: 48px;
        }
    }
}
.banner8_btn {
    width: 290px;
    height: 48px;
    background: #FF7B00;
    border-radius: 4px;
    font-size: 24px;
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: calc(50% - 145px);
}
</style>
<style lang="less">
.banner8{
    .el-form-item{
        margin-bottom: 17px;
    }
  .el-form-item__label {
    width: 557px;
    font-size: 20px;
    color: #333333;
    text-align: left;
    padding-right: 0;
    font-weight: 700;
  }
  .el-textarea__inner {
    resize: none;
  }
}
</style>